.create-padding(@number) {
  .s-@{number} {
    padding-top: (1px * @number);
  }
}

.loop(@cursor) when (@cursor >= 0) {
  // next iteration
  .loop((@cursor - 1));

  // actual printing
  .create-padding((@cursor * 4));
}

// launch the loop
.loop(15);
