.newsletter-section {
  padding: 80px 0;

  @media @screen767 {
    padding: 60px 0;
  }

  .newsletter-wrapper {
    max-width: 1000px;
    width: 100%;
    margin: auto;

    &.thank-you {
      padding: 68px 0;

      @media @screen767 {
        padding: 101px 0;
      }

      .title {
        margin: 0;

        @media @screen767 {
          max-width: 265px;
          width: 100%;
          margin: auto;
        }
      }
    }

    .title {
      .display-h1;
      .mb-8;

      @media @screen767 {
        .display-h3;
      }
    }

    .description {
      .paragraph-l-xl;

      @media @screen767 {
        .paragraph-m;
      }
    }

    .subscribe-group {
      display: flex;
      max-width: 524px;
      width: 100%;
      gap: 16px;
      margin: auto;
      margin-top: 40px;

      @media @screen767 {
        flex-direction: column;
        max-width: 100%;
        gap: 18px;
      }

      .form-group {
        flex: 1;

        .ant-input {
          max-height: 54px;

          @media @screen767 {
            max-height: 44px;
          }
        }
      }


    }
  }
}