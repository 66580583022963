.html-text-block {
  blockquote {
    margin: 0;
    padding: 16px;
    border-inline-start: 2px solid @tq-neutral-1000;
  }

  div {
    .mb-40;

    @media @screen1024 {
      .mb-32;
    }

    @media @screen767 {
      .mb-20;
    }

    .ant-btn {
      color: #004bd9;
      text-decoration: underline;
      text-decoration-color: #004bd9;
      height: auto;
      padding: 0px;
      border: 0;
      border-bottom: 1px solid;
      border-radius: 0;
      .p-0;

      &.small {
        font: normal 500 16px/140% var(--tq-primary-font);
        letter-spacing: 0.32px;

        @media @screen767 {
          font: normal 500 14px/140% var(--tq-primary-font);
        }
      }

      &.medium {
        font: normal 500 18px/140% var(--tq-primary-font);
        letter-spacing: 0.36px;

        @media @screen767 {
          font: normal 500 16px/140% var(--tq-primary-font);
        }
      }

      &.large {
        font: normal 500 20px/140% var(--tq-primary-font);
        letter-spacing: 0.4px;

        @media @screen767 {
          font: normal 500 18px/140% var(--tq-primary-font);
        }
      }

      &.extra-large {
        font: normal 500 24px/140% var(--tq-primary-font);
        letter-spacing: 0.48px;

        @media @screen767 {
          font: normal 500 20px/140% var(--tq-primary-font);
        }
      }
    }
  }

  h1,
  .h1 {
    font: var(--tq-display-h1);
  }

  h2,
  .h2 {
    font: var(--tq-display-h2);
  }

  h3,
  .h3 {
    font: var(--tq-display-h3);
  }

  h4,
  .h4 {
    font: var(--tq-display-h4);
  }

  h5,
  .h5 {
    font: var(--tq-display-h5);
  }

  h6,
  .h6 {
    font: var(--tq-display-h6);
  }

  .underline {
    text-decoration: underline;
  }

  .bold {
    font-weight: 600;
  }

  .strike {
    text-decoration: line-through;
  }

  .emphasized {
    font-style: italic;
  }

  .bulleted {
    display: flex;
    align-items: baseline;

    > div {
      .mb-0;
    }

    &:before {
      content: '•';
      text-decoration: none;
      padding: 0 10px;
    }
  }
}
