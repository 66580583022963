/*author card css start here*/
.authorcard {
  max-width: 248px;
  width: 100%;

  &.authorcardList {
    .d-flex;
    padding-bottom: 20px;
    margin-bottom: 20px;
    align-items: flex-start;
    height: 100%;
    max-width: 375px;
    width: 100%;
    box-shadow: @tq-shadow-bottom;

    @media @screen767 {
      padding: 16px;
      margin: 0;
    }

    .auther-img {
      width: 19.127%;
      padding-top: 24.98%;
      // max-width: 232px;
      overflow: hidden;

      @media @screen767 {
        width: 30.323%;
        padding-top: 39.654%;
      }


    }

    .author-description {
      .m-0;
      .flex-1;
      padding-left: 12px;
      gap: 4px;

      .ant-typography:not(:last-of-type) {
        margin-bottom: 4px;
      }

      .speaker {
        font: @tq-text-lg-bold;

        @media @screen767 {
          font: @tq-text-md-bold;
        }
      }

      .videocount {
        font: @tq-text-small;
      }

      .description {
        font: @tq-paragraph-m;

        @media @screen767 {
          font: @tq-text-small;
        }
      }
    }
  }

  .auther-img {
    padding-top: 129.84%;
    border-radius: 8px;
    overflow: hidden;

    @media @screen767 {
      padding-top: 129.42%;
    }

    img {
      transform: scale(1);
      -webkit-transition: all 0.4s ease-in-out;
      -moz-transition: all 0.4s ease-in-out;
      -o-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }

  .author-description {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 6px;

    @media @screen767 {
      margin-top: 8px;
      gap: 4px;
    }

    * {
      margin: 0;
    }
  }
}

.author-carousel {
  .authorcard {
    min-width: 248px;

    @media @screen767 {
      min-width: 136px;
    }

    .auther-img {
      padding-top: 130.63%;
      overflow: hidden;

      @media @screen767 {
        padding-top: 130%;
      }


    }

    .author-description {
      .videocount {
        .text-small;
      }
    }
  }

  .slick-slide {
    margin: 0 8px;


    width: calc(calc(100vw - 175px) / 6.1);

    @media @screen1450 {

      width: calc(calc(100vw - 175px) / 5.1);

    }

    @media @screen1150 {
      width: calc(calc(100vw - 140px) / 4.1);
    }

    @media @screen1024 {
      width: calc(calc(100vw - 130px) / 3.1);
    }

    @media @screen767 {
      width: calc(calc(100vw - 39px) / 3.1);
      margin: 0 4px;
    }

    @media @screen540 {
      width: calc(calc(100vw - 39px) / 2.1);
    }

    .authorcard {
      min-width: 100%;
      max-width: 100%;
      width: 100%;
    }
  }
}

.authorlist-section {
  &.separatoroff {
    .section-header {
      box-shadow: none;
    }

    .authorcard.authorcardList {
      box-shadow: none;
    }
  }

  .section-header {
    padding-bottom: 16px;
    box-shadow: @tq-shadow-bottom;
    margin-bottom: 24px;

    @media @screen767 {
      padding: 16px;
      padding-top: 0;
      margin: 0;
    }

    .ant-typography {
      margin: 0;
    }
  }

  .authorcard.authorcardList {
    max-width: 100%;
  }
}

.authorgrid {
  .authorcard {
    max-width: 100%;

    .author-description .videocount {
      .text-small;
    }
  }
}

/*author card css end here*/